import React from "react"
import { Trans } from "gatsby-plugin-react-i18next";

// styles
import "./Content.css";
import { pageDirection } from "../../helpers/utils";

const ContentHero = ({ title, subtitle, description }) => {
  const dir = pageDirection();
  return (
    <div dir={dir} className="container-lg terms-of-use-bg">
      <section className="content-hero-container">
        <div className="content-texts-hero-container">
          <h1 className="d-flex">
            <Trans>{title}</Trans>
          </h1>
          <h3 className="d-flex">
            <Trans>
              {subtitle}
            </Trans>
          </h3>
          <p className="d-flex">
            <Trans>
              {description}
            </Trans>
          </p>
        </div>
      </section>
    </div>
  )
}

export default ContentHero
